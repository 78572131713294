import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./categories.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBath, faBuilding, faCar, faCouch, faGem, faGift, faHeartbeat, faIndustry, faLightbulb, faShoePrints, faSolarPanel, faSuitcase, faTools, faTruck, faTv, faWrench } from '@fortawesome/free-solid-svg-icons';

const allItems = [
  { id: 1, icon: <FontAwesomeIcon icon={faGem} />, category: "Jewelry, Eyewear", link: "/category/Jewelry, Eyewear" },
  { id: 2, icon: <FontAwesomeIcon icon={faCar} />, category: "Vehicle Parts & Accessories", link: "/category/Vehicle Parts & Accessories" },
  { id: 3, icon: <FontAwesomeIcon icon={faIndustry} />, category: "Industrial Machinery", link: "/category/Industrial Machinery" },
  { id: 4, icon: <FontAwesomeIcon icon={faSuitcase} />, category: "Luggage, Bags & Cases", link: "/category/Luggage, Bags & Cases" },
  { id: 5, icon: <FontAwesomeIcon icon={faBuilding} />, category: "Construction & Real Estate", link: "/category/Construction & Real Estate" },
  { id: 6, icon: <FontAwesomeIcon icon={faBath} />, category: "Personal Care & Household", link: "/category/Personal Care & Household" },
  { id: 7, icon: <FontAwesomeIcon icon={faLightbulb} />, category: "Lights & Lighting", link: "/category/Lights & Lighting" },
  { id: 8, icon: <FontAwesomeIcon icon={faSolarPanel} />, category: "Renewable Energy", link: "/category/Renewable Energy" },
  { id: 9, icon: <FontAwesomeIcon icon={faShoePrints} />, category: "Shoes & Accessories", link: "/category/Shoes & Accessories" },
  { id: 10, icon: <FontAwesomeIcon icon={faCouch} />, category: "Furniture", link: "/category/Furniture" },
  { id: 11, icon: <FontAwesomeIcon icon={faWrench} />, category: "Tools & Hardware", link: "/category/Tools & Hardware" },
  { id: 12, icon: <FontAwesomeIcon icon={faTv} />, category: "Home Appliances", link: "/category/Home Appliances" },
  { id: 13, icon: <FontAwesomeIcon icon={faTruck} />, category: "Vehicles & Transportation", link: "/category/Vehicles & Transportation" },
  { id: 14, icon: <FontAwesomeIcon icon={faTools} />, category: "Vehicle Accessories", link: "/category/Vehicle Accessories" },
  { id: 15, icon: <FontAwesomeIcon icon={faGift} />, category: "Gifts & Crafts", link: "/category/Gifts & Crafts" },
  { id: 16, icon: <FontAwesomeIcon icon={faHeartbeat} />, category: "Health Care", link: "/category/Health Care" },
];

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const Categories = () => {
  const groupedItems = chunkArray(allItems, 10);

  return (
    <div className="container my-5">
      <Carousel controls={true} interval={null}>
        {groupedItems.map((group, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-around flex-wrap">
              {group.map((item) => (
                <div key={item.id} className="text-center m-3">
                  <Link to={item.link} style={{ textDecoration: "none", color: "black" }}>
                    <div className="category-icon-wrapper">{item.icon}</div>
                    <p className="category-name">{item.category}</p>
                  </Link>
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Categories;
