import React, { useState, useEffect } from 'react';
import QualityAssuranceNavbar from './QualityAssuranceNavbar';

const SellerComplaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch complaints data from the API
    const fetchComplaints = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_LOCALHOST_URL}/complaints`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setComplaints(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchComplaints();
  }, []);

  return (
    <div>
      <div className="container-fluid m-0 p-0 navbar-dark bg-dark shadow">
        <QualityAssuranceNavbar />
      </div>
    <div className="container mt-4">
      <h2 className="text-center mb-4" style={{ fontSize: '1.8rem' }}>Seller Complaints</h2>
      {loading && <p>Loading complaints...</p>}
      {error && <p className="text-danger">Error: {error}</p>}
      {!loading && !error && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Complaint</th>
              </tr>
            </thead>
            <tbody>
              {complaints.length > 0 ? (
                complaints.map((complaint, index) => (
                  <tr key={index}>
                    <td>{complaint.email}</td>
                    <td>{complaint.role}</td>
                    <td>{complaint.complaint}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No complaints found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </div>
  );
};

export default SellerComplaints;
