import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./featureProducts.css";
import { CurrencyContext } from "../../../CurrencyContext";

const FeatureProducts = () => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(9);
  const { currency, rates } = useContext(CurrencyContext);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCALHOST_URL}/products/approved/xx`
      );
      console.log("Fetched products:", response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const showMoreProducts = () => {
    setVisibleProducts((prev) => prev + 8); // Load 8 more products each time
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <section className="featured-products fp-outer">
        <div
          className="container-fluid"
          style={{ paddingTop: "40px", width: "80%" }}
        >
          <div className=" product-list-custom" style={{ width: "100%" }}>
            {products.length > 0 ? (
              products.slice(0, visibleProducts).map((product) => { 
                const convertedPrice = (product.price * (rates[currency] || 1)).toFixed(2);
                
                return(
                <div className=" product-card-custom " key={product.id}>
                  <img
                    src={`${process.env.REACT_APP_LOCALHOST_URL}${product.imageUrl}`}
                    alt={product.name}
                    className="product-img"
                  />
                  <div className="product-details">
                    <Link
                      to={`/productoverview/${product._id}`}
                      className="nameLink"
                    >
                      <h3>{product.name}</h3>
                    </Link>
                    <p>{`${currency} ${convertedPrice}`}</p>
                    <Link
                      to={`/productoverview/${product._id}`}
                      className="btn btn-secondary view-details-btn"
                      style={{ backgroundColor: "#EF5B2B" }}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              )})
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "40px 400px",
                  color: "#EF5B2B",
                }}
              >
                <h2>No Products Available</h2>
                <p>
                  Sorry, we couldn't find any products that match your criteria.
                </p>
              </div>
            )}
          </div>
          {visibleProducts < products.length && (
            <div className="d-flex justify-content-center mt-4">
              <button onClick={showMoreProducts} className="btn btn-warning m-3">
                See More Products
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default FeatureProducts;
