import { React, useState } from "react";
import FullNavbar from "./FullNavbar";
import styles from "./AccountSetting.module.css";

export default function AccountSetting() {
  const [logoPreview, setLogoPreview] = useState(null);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result); // Update logo preview
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="container-fluid m-0 p-0 navbar-dark bg-dark shadow">
        <FullNavbar />
      </div>

      <div className={styles.settingsPage}>
        <div className={styles.settingsContainer}>
          <div className={`${styles.card} shadow-lg`}>
            <div className={styles.header}>
              <div className={styles.logoWrapper}>
                {/* Logo */}
                <img
                  src={
                    logoPreview ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAHXPluq6GtTRPDIHRv5kJPy86uFjp5sO7hg&s"
                  }
                  alt="Logo"
                  className={styles.logo}
                />
                {/* File Input */}
                <div className={styles.fileUploadWrapper}>
                  <input
                    type="file"
                    id="logoUpload"
                    className={styles.fileInputLogo}
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                  <label
                    htmlFor="logoUpload"
                    className={styles.fileUploadLabel}
                  >
                    Change Logo
                  </label>
                </div>
              </div>
              <h2 className={styles.title}>Seller Account Settings</h2>
            </div>

            {/* Form */}
            <form className={styles.form}>
              {/* Name & Email */}
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="companyName" className={styles.label}>
                    Company or Store Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    className={styles.input}
                    placeholder="Enter company or shop name..."
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="businessType" className={styles.label}>
                    Business Type
                  </label>
                  <select id="businessType" className={styles.select}>
                    <option>Select business type</option>
                    <option value="manufacturer">Manufacturer</option>
                    <option value="supplier">Supplier</option>
                    <option value="wholeseller">wholesaler</option>
                    <option value="distributor">Distributor</option>
                    <option value="retailer">Retailer</option>
                  </select>
                </div>
              </div>

              <div className={styles.textareaWrapper}>
                <label htmlFor="description" className={styles.labeltextarea}>
                  About Your Business
                </label>
                <textarea
                  id="description"
                  className={styles.textarea}
                  placeholder="Enter details here..."
                  rows="5"
                  maxLength="500"
                ></textarea>
              </div>

              {/* Phone & Business Name */}
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="phoneNumber" className={styles.label}>
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    className={styles.input}
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="Address" className={styles.label}>
                    Address
                  </label>
                  <input
                    type="text"
                    id="Address"
                    className={styles.input}
                    placeholder="Enter your Address"
                  />
                </div>
              </div>
              {/* sales */}
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="sales" className={styles.label}>
                    Accumulative Sales (Monthly)
                  </label>
                  <input
                    type="number"
                    id="sales"
                    className={styles.input}
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="secpDocuments" className={styles.label}>
                    Company Legal Documents{" "}
                    <span className="text-warning">
                      * SECP Documents if applicable
                    </span>
                  </label>
                  <input
                    type="file"
                    id="secpDocuments"
                    className={styles.fileInput}
                  />
                </div>
              </div>

              {/* Dropdowns */}
              {/* <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="businessType" className={styles.label}>
                    Business Type
                  </label>
                  <select id="businessType" className={styles.select}>
                    <option>Select business type</option>
                    <option value="individual">Individual</option>
                    <option value="small-business">Small Business</option>
                    <option value="enterprise">Enterprise</option>
                  </select>
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="country" className={styles.label}>
                    Country
                  </label>
                  <select id="country" className={styles.select}>
                    <option>Select country</option>
                    <option value="usa">United States</option>
                    <option value="uk">United Kingdom</option>
                    <option value="india">India</option>
                  </select>
                </div>
              </div> */}

              {/* File Uploads */}
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="idCard" className={styles.label}>
                    Upload ID Card{" "}
                    <span className="text-warning">
                      * Optional if SECP Documents not applicable
                    </span>
                  </label>
                  <input type="file" id="idCard" className={styles.fileInput} />
                </div>
                <div className={styles.formGroup} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                  <div className={styles.checkboxWrapper}>
                    <input
                      type="checkbox"
                      id="policy"
                      className={styles.checkbox}
                      required
                    />
                    <label htmlFor="policy" className={styles.checkboxLabel}>
                      I agree to the{" "}
                      <a href="#" className={styles.policyLink}>
                        terms and conditions
                      </a>
                      .
                    </label>
                  </div>
                </div>
              </div>

              {/* Save Button */}
              <div className={styles.buttonContainer}>
                <button type="submit" className={styles.saveButton}>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
