import React, { useState } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import "./CategorySection.css"; // Import the CSS file for custom styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBuilding,
  faCar,
  faCouch,
  faGem,
  faGift,
  faHeartbeat,
  faIndustry,
  faLightbulb,
  faShoePrints,
  faSolarPanel,
  faSuitcase,
  faTools,
  faTruck,
  faTv,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CategorySection = ({setShowCategories}) => {
  const allItems = [
    {
      id: 1,
      icon: <FontAwesomeIcon icon={faGem} />,
      category: "Jewelry, Eyewear",
      link: "/category/Jewelry, Eyewear",
    },
    {
      id: 2,
      icon: <FontAwesomeIcon icon={faCar} />,
      category: "Vehicle Parts & Accessories",
      link: "/category/Vehicle Parts & Accessories",
    },
    {
      id: 3,
      icon: <FontAwesomeIcon icon={faIndustry} />,
      category: "Industrial Machinery",
      link: "/category/Industrial Machinery",
    },
    {
      id: 4,
      icon: <FontAwesomeIcon icon={faSuitcase} />,
      category: "Luggage, Bags & Cases",
      link: "/category/Luggage, Bags & Cases",
    },
    {
      id: 5,
      icon: <FontAwesomeIcon icon={faBuilding} />,
      category: "Construction & Real Estate",
      link: "/category/Construction & Real Estate",
    },
    {
      id: 6,
      icon: <FontAwesomeIcon icon={faBath} />,
      category: "Personal Care & Household",
      link: "/category/Personal Care & Household",
    },
    {
      id: 7,
      icon: <FontAwesomeIcon icon={faLightbulb} />,
      category: "Lights & Lighting",
      link: "/category/Lights & Lighting",
    },
    {
      id: 8,
      icon: <FontAwesomeIcon icon={faSolarPanel} />,
      category: "Renewable Energy",
      link: "/category/Renewable Energy",
    },
    {
      id: 9,
      icon: <FontAwesomeIcon icon={faShoePrints} />,
      category: "Shoes & Accessories",
      link: "/category/Shoes & Accessories",
    },
    {
      id: 10,
      icon: <FontAwesomeIcon icon={faCouch} />,
      category: "Furniture",
      link: "/category/Furniture",
    },
    {
      id: 11,
      icon: <FontAwesomeIcon icon={faWrench} />,
      category: "Tools & Hardware",
      link: "/category/Tools & Hardware",
    },
    {
      id: 12,
      icon: <FontAwesomeIcon icon={faTv} />,
      category: "Home Appliances",
      link: "/category/Home Appliances",
    },
    {
      id: 13,
      icon: <FontAwesomeIcon icon={faTruck} />,
      category: "Vehicles & Transportation",
      link: "/category/Vehicles & Transportation",
    },
    {
      id: 14,
      icon: <FontAwesomeIcon icon={faTools} />,
      category: "Vehicle Accessories",
      link: "/category/Vehicle Accessories",
    },
    {
      id: 15,
      icon: <FontAwesomeIcon icon={faGift} />,
      category: "Gifts & Crafts",
      link: "/category/Gifts & Crafts",
    },
    {
      id: 16,
      icon: <FontAwesomeIcon icon={faHeartbeat} />,
      category: "Health Care",
      link: "/category/Health Care",
    },
  ];

  const [hoveredCategory, setHoveredCategory] = useState(null);

  // Handler for hovering over parent category
  const handleCategoryHover = (category) => {
    setHoveredCategory(category);
  };

  const HideNavbar = ()=>{
    setShowCategories(false);
  }

  return (
    <Container fluid="md" className="my-4 position-absolute left-0 mt-1 z-3" >
      <Row>
        {/* Parent Categories */}
        <Col md={4} className="m-0 p-0 ms-2" onMouseLeave={()=> setShowCategories(!setHoveredCategory)}>
          <ListGroup className="scrollable-list">
            {allItems.map((category) => (
              <Link to={category?.link} className="link-category" onClick={HideNavbar}>
                <ListGroup.Item
                  key={category.id}
                  onMouseEnter={() => handleCategoryHover(category)}
                  className="list-group-item-action"
                >
                  {category.category}
                </ListGroup.Item>
              </Link>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default CategorySection;
