import React, { useState } from "react";
import "./helpcenter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
export default function HelpCenter() {
  // State to store search query
  const [searchQuery, setSearchQuery] = useState("");

  // FAQ Data
  const faqData = [
    {
      id: "headingOne",
      question: "What is TradeXSell?",
      answer:
        "TradeXSell is your trusted online marketplace for buying and selling high-quality products. We provide a seamless, secure, and convenient platform where buyers and sellers connect to find the best deals and grow their businesses.",
    },
    {
      id: "headingTwo",
      question: "How can I create an account on TradeXSell?",
      answer:
        "To create an account, simply click on the “Sign Up” button on our homepage. Fill in the required information, including your email address and a secure password, then follow the prompts to verify your account.",
    },
    {
      id: "headingThree",
      question: "What types of products can I buy or sell on TradeXSell?",
      answer:
        "TradeXSell offers a wide range of products across various categories, including electronics, fashion, home goods, beauty products, and more. Sellers can list their products in relevant categories to reach their target audience.",
    },
    {
      id: "headingFour",
      question: "Is it safe to buy from sellers on TradeXSell?",
      answer:
        "Yes, we prioritize the safety and security of our users. All transactions are encrypted, and we provide buyer protection to ensure a safe shopping experience. Additionally, sellers are vetted to maintain quality and reliability.",
    },
    {
      id: "headingFive",
      question: "How do I make a purchase on TradeXSell?",
      answer:
        "To make a purchase, browse our listings, select the product you wish to buy, and click on the “Add to Cart” button. Once you’re ready, proceed to checkout, where you can enter your shipping details and payment information.",
    },
    {
      id: "headingSix",
      question: "What payment methods are accepted on TradeXSell?",
      answer:
        "TradeXSell accepts various payment methods, including credit/debit cards, PayPal, and other secure payment gateways.Choose your preferred method during the checkout process.",
    },
    {
      id: "headingSeven",
      question: "How can I track my order?",
      answer:
        "After your order is shipped, you will receive a tracking number via email. You can use this number to track your order status on the shipping carrier’s website or through your TradeXSell account.",
    },
    {
      id: "headingEight",
      question: "What should I do if I encounter an issue with my order?",
      answer:
        "If you experience any issues with your order, please contact our customer support team through the “Contact Us” page. We are here to assist you and resolve any concerns you may have.",
    },
    {
      id: "headingNine",
      question: "Are there any fees for selling on TradeXSell?",
      answer:
        "Yes, we charge a nominal fee for each sale made through our platform. This fee helps us maintain a secure and efficient marketplace for both buyers and sellers. Please check our Seller Agreement for detailed information on fees.",
    },
    {
      id: "headingTen",
      question: "How can I contact customer support?",
      answer:
        "You can reach our customer support team via the “Contact Us” page on our website. We also offer support through email and a live chat feature during business hours.",
    },
  ];

  // Filter FAQs based on search query
  const filteredFAQs = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a
            className="navbar-brand fw-bold "
            href="#"
            style={{ color: "#ef5d26" }}
          >
            TRADEXSELL
          </a>
          <a className="navbar-brand" href="#">
            Help Center
          </a>
          <div className="navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item me-3" style={{ cursor: "pointer" }}>
                <span className="nav-link text-secondary">FAQs</span>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Search Input Section */}
      <section className="help-center-search text-center text-white d-flex align-items-center justify-content-center">
        <div className="container">
          <h1 className="display-5 mb-4 fw-bold">How can we help you?</h1>
          <div className="input-group w-75 mx-auto">
            <input
              type="text"
              className="form-control searchinput"
              placeholder="Search for topics, guides, or FAQs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search"
            />
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <div className="container mb-5 mt-4">
        <h2 className="text-center mb-4">Frequently Asked Questions</h2>
        <div className="accordion" id="faqAccordion">
          {filteredFAQs.length > 0 ? (
            filteredFAQs.map((faq, index) => (
              <div className="accordion-item" key={faq.id}>
                <h2 className="accordion-header" id={faq.id}>
                  <button
                    className={`accordion-button ${
                      index === 0 ? "" : "collapsed"
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${faq.id}`}
                    aria-expanded={index === 0 ? "true" : "false"}
                    aria-controls={`collapse${faq.id}`}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`collapse${faq.id}`}
                  className={`accordion-collapse collapse ${
                    index === 0 ? "show" : ""
                  }`}
                  aria-labelledby={faq.id}
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">{faq.answer}</div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">No FAQs match your search query.</p>
          )}
        </div>
      </div>

      <div className="container my-5">
        <h2 className="text-center mb-4">Get in Touch</h2>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div
              className="card shadow-sm"
              style={{ backgroundColor: "#ef5d26", color: "#fff" }}
            >
              <div className="card-body">
                <h4 className="">We're Here to Help!</h4>
                <p className="card-text mb-2">
                  If you have any questions or need assistance, feel free to
                  reach out to us. Our team is here to help!
                </p>
                <ul className="list-unstyled">
                  <li>
                    <strong>Email:</strong>{" "}
                    <a
                      href="mailto:support@tradexsell.com"
                      className="text-white"
                    >
                      support@tradexsell.com
                    </a>
                  </li>
                  <li>
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+11234567890" className="text-white">
                      +1 (123) 456-7890
                    </a>
                  </li>
                  <li>
                    <strong>Address:</strong> 123 TradeXSell St, Marketplace
                    City, CA 90210
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
