import React from "react";
import { useNavigate } from "react-router-dom";

export default function GetStarted() {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate("/register");
  };
  return (
    <div className="container-fluid p-0">
     
        <div
          className="bg-image"
          style={{
            backgroundImage:
              "url('https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_1280.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "400px", 
            position: "relative",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "rgb(239, 91, 43, 0.9)", 
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <div
            className="content position-relative text-center text-white"
            style={{
              position: "relative",
              zIndex: 1,
              paddingTop: "100px",
              
            }}
          >
            <h1 className="fw-bold">Ready to get started?</h1>
            <p className="p-3 fw-bolder">
            Uncover millions of products from reputable suppliers—sign up now!
            </p>
            <button className="btn btn-light rounded-5 px-5 fw-bold py-3" onClick={handleSignUpClick}>Sign Up</button>{" "}

          </div>
        </div>
      
    </div>
  );
}
