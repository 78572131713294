import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styles from "./Feedback.module.css";
import { AuthContext } from "../../AuthContext.js";
import { Link } from "react-router-dom";

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);
  const { email: sellerEmail } = useContext(AuthContext);

  const [products, setProducts] = useState([]);
  const [recentReviews, setRecentReviews] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productRequests = recentReviews.map((review) =>
          axios.get(
            `${process.env.REACT_APP_LOCALHOST_URL}/products/product/${review.productId}`
          )
        );

        const productResponses = await Promise.all(productRequests);

        const productData = productResponses.map((response) => response.data);

        setProducts(productData);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    if (recentReviews.length > 0) {
      fetchProducts();
    }
  }, [recentReviews]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recentReviewsResponse = await fetch(
          `${process.env.REACT_APP_LOCALHOST_URL}/seller/recent-reviews/${sellerEmail}`
        );

        const recentReviewsData = await recentReviewsResponse.json();
        setRecentReviews(
          Array.isArray(recentReviewsData) ? recentReviewsData : []
        );
      } catch (error) {
        console.error("Error fetching seller data:", error);
      }
    };

    if (sellerEmail) {
      fetchData();
    }
  }, [sellerEmail]);

  useEffect(() => {
    if (recentReviews.length > 0 && products.length > 0) {
      const combined = recentReviews
        .map((review) => {
          const matchingProduct = products.find(
            (product) => product.id.toString() === review.productId.toString()
          );
          if (matchingProduct) {
            return {
              ...review,
              product: matchingProduct,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      setCombinedData(combined);
    }
  }, [recentReviews, products]);

  console.log("combine data", combinedData)
  return (
    <div className={styles.feedbackContainer}>
      <h2 className={styles.heading}>Customer Feedbacks</h2>
      <div className={styles.feedbackList}>
        {combinedData.length > 0 ? (
          combinedData.map((review) => (
            <div key={review._id} className={styles.feedbackCard}>
              <div className={styles.feedbackHeader}>
                <img
                  src={`${process.env.REACT_APP_LOCALHOST_URL}${review?.product?.imageUrl}`}
                  alt={review?.product?.name}
                  className={styles.productImage}
                />
                <div className={styles.ProductUsername}>
                  <Link to={`/adminproducts/${review.productId}`}><p className={styles.productName}>{review?.product?.name}</p></Link>
                  <p className={styles.username}>{review.username}</p>
                </div>
              </div>
              <div className={styles.feedbackBody}>
                <p className={styles.reviewText}>"{review.review}"</p>
                <p className={styles.userEmail}>{review.userEmail}</p>
              </div>
            </div>
          ))
        ) : (
          <p className={styles.noFeedback}>No feedbacks available.</p>
        )}
      </div>
    </div>
  );
};

export default Feedback;
