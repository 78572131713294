import React, { useState, useContext } from "react";
import "./login.css"; // Import useUser hook
import { AuthContext } from "../../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(""); // State for error messages
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();
  const { handleLogin } = useContext(AuthContext);

  const handleLoginValidation = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
      });
      const data = await response.json();

      if (response.ok) {
        // Successful registration
        setSuccessMessage("Login successfully!"); // Set success message
        setError(""); // Clear any previous error

        // Remove success message after 3 seconds and redirect
        setTimeout(() => {
          setSuccessMessage(""); // Clear the success message
        }, 1000);
      } else {
        // Handle error if email already exists
        if (data.error) {
          setError(data.error); // Set error message
        }
      }

      if (data.success) {
        handleLogin(
          data.user.email,
          data.user.username,
          data.user.role,
          data.user.id
        );

        // Redirect based on the role
        if (data.user.role === "user") {
          window.location.href = "/";
        } else if (data.user.role === "seller") {
          window.location.href = "/admin/sellerdashboard";
        } else if (data.user.role === "MainAdmin") {
          window.location.href = "/admin/checksellers";
        } else if (data.user.role === "QualityAssurance") {
          window.location.href = "/quality/manageproducts";
        }
      } else {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    }
  };

  const styles = {
    container: {
      display: "flex",
      minHeight: "100vh",
      backgroundColor: "#f7fafc",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "2rem",
    },
    formBox: {
      width: "100%",
      maxWidth: "400px",
      padding: "2rem",
      backgroundColor: "white",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
    header: {
      marginBottom: "2rem",
      textAlign: "center",
      fontSize: "1.875rem",
      fontWeight: "600",
      color: "#EF5D2E ",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
    inputContainer: {
      position: "relative",
    },
    input: {
      width: "100%",
      padding: "0.5rem 0.75rem",
      paddingLeft: "2.5rem",
      border: "1px solid #e2e8f0",
      borderRadius: "0.375rem",
      fontSize: "1rem",
    },
    icon: {
      position: "absolute",
      left: "0.75rem",
      top: "50%",
      transform: "translateY(-50%)",
      color: "#a0aec0",
    },
    forgotPassword: {
      textAlign: "right",
      fontSize: "0.875rem",
      color: "#EF5B2B",
      cursor: "pointer",
    },
    button: {
      width: "100%",
      padding: "0.5rem",
      backgroundColor: "#EF5B2B",
      color: "white",
      border: "none",
      borderRadius: "0.375rem",
      fontSize: "1rem",
      cursor: "pointer",
    },
    success: {
      marginTop: "1rem",
      padding: "0.5rem",
      backgroundColor: "#c6f6d5",
      color: "#2f855a",
      borderRadius: "0.375rem",
    },
    alert: {
      marginTop: "1rem",
      padding: "0.5rem",
      backgroundColor: "#fed7d7",
      color: "#9b2c2c",
      borderRadius: "0.375rem",
    },
    registerMessage: {
      marginTop: "1rem",
      textAlign: "center",
      fontSize: "0.875rem",
      color: "#4a5568",
    },
    registerLink: {
      color: "#EF5B2B",
      cursor: "pointer",
    },
  };

  return (
    <div className="topLogin-container">
      <div className="login-container">
        <h2 className="login-title">Login</h2>
        <form className="login-form" onSubmit={handleLoginValidation}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div
              className="password-input-container"
              style={{ position: "relative" }}
            >
              <input
                type={showPassword ? "text" : "password"} // Toggle input type
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
              <span
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                {/* Icon changes based on visibility */}
              </span>
            </div>
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        {successMessage && (
          <div style={styles.success}>{successMessage}</div>
        )}{" "}
        {/* Show success message */}
        {showAlert && (
          <div style={styles.alert}>
            Invalid email or password. Please try again.
          </div>
        )}
        <p className="account-info-text">
          Don't have an account?{" "}
          <Link className="account-info-text-link" to="/register">
            Register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
