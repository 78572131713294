import axios from "axios";
import { Filter, Link, RefreshCw, Search, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import ProductDetailsPopup from "../ProductDetailspopup";
import QualityAssuranceNavbar from "./QualityAssuranceNavbar";
import { MdChat } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";

function ManageProducts() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCALHOST_URL}/products/all/x`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus =
      currentStatus === "approved" ? "not approved" : "approved";
    let remarks = "";

    if (newStatus === "not approved") {
      remarks = prompt(
        "Please provide a reason for not approving the product:"
      );
      if (!remarks) {
        alert(
          'Remarks are required when changing the status to "not approved".'
        );
        return; // Exit if no remarks are provided
      }
    }

    try {
      // Update the status of the product
      await axios.patch(
        `${process.env.REACT_APP_LOCALHOST_URL}/products/updatestatus/${id}`,
        { status: newStatus }
      );

      // If the status is "not approved" and remarks are provided, update the remarks
      if (newStatus === "not approved" && remarks) {
        await axios.patch(
          `${process.env.REACT_APP_LOCALHOST_URL}/products/remarks/${id}`,
          { remarks }
        );
      }

      // Update the local product list to reflect the changes
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === id
            ? { ...product, status: newStatus, remarks }
            : product
        )
      );
    } catch (error) {
      console.error("Error updating product status or remarks:", error);
    }
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (typeFilter ? product.type === typeFilter : true) &&
      (statusFilter ? product.status === statusFilter : true)
  );

  const handleDetailsClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseProductPopup = () => {
    setSelectedProduct(null);
  };

  console.log("filteredProducts", filteredProducts);
  const senderId = localStorage.getItem("id");
  
  const startNewChat = async (product) => {
    const sellerEmail = product?.sellerEmail;
    console.log("seller email", sellerEmail);
    
    const response = await axios.get(`${process.env.REACT_APP_LOCALHOST_URL}/users/user/${sellerEmail}`);
    const receiverId = response?.data?._id;
    console.log("receiverId", receiverId);

    try {
      // Hit the API to start a new chat
      const response = await axios.post(`${process.env.REACT_APP_LOCALHOST_URL}/chat/start`, {
        senderId,
        receiverId,
      });

      // If the chat is successfully created, navigate to the /chat page
      if (response.data.chat) {
        console.log('Chat started:', response.data.chat);
        navigate(`/chat`); // Navigate to the chat page
      } else {
        console.error('Error creating chat:', response.data.message);
      }
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };
  return (
    <div>
      <div className="container-fluid m-0 p-0 navbar-dark bg-dark shadow">
        <QualityAssuranceNavbar />
      </div>
      <div
        className="manage-products"
        style={{
          backgroundColor: "#f8f9fa",
          minHeight: "100vh",
          padding: "40px 0",
        }}
      >
        <div
          className="container"
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          <h1
            style={{
              color: "#ef5b2b",
              marginBottom: "30px",
              fontSize: "2.5rem",
              fontWeight: "bold",
            }}
          >
            Manage Products
          </h1>

          <div className="search-bar mb-3 d-flex flex-wrap align-items-center gap-3">
            {/* Search Input */}
            <div
              className="position-relative flex-grow-1"
              style={{ maxWidth: "400px" }}
            >
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control px-3"
                style={{
                  padding: "10px 40px 10px 15px",
                  borderRadius: "25px",
                }}
              />
              <Search
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6c757d",
                }}
              />
            </div>

            {/* Filter Icon */}
            <div className="d-flex align-items-center">
              <Filter
                size={24}
                className="text-secondary"
                style={{ cursor: "pointer" }}
              />
            </div>

            {/* Type Filter */}
            <div className="d-flex align-items-center">
              <label htmlFor="typeFilter" className="me-2 fw-bold">
                Type:
              </label>
              <select
                id="typeFilter"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
                className="form-select"
                style={{
                  padding: "10px",
                  borderRadius: "25px",
                  maxWidth: "150px",
                }}
              >
                <option value="">All</option>
                <option value="local">Local</option>
                <option value="international">International</option>
              </select>
            </div>

            {/* Status Filter */}
            <div className="d-flex align-items-center">
              <label htmlFor="statusFilter" className="me-2 fw-bold">
                Status:
              </label>
              <select
                id="statusFilter"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="form-select"
                style={{
                  padding: "10px",
                  borderRadius: "25px",
                  maxWidth: "150px",
                }}
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="not approved">Not Approved</option>
              </select>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate("/chat");
                }}
                className="btn  rounded-pill shadow-sm mt-1 ms-2"
                style={{
                  padding: "7px 40px",
                  backgroundColor: "#f1582b",
                  color: "white",
                }}
              >
                <MdChat />
                <span className="ms-1">Chat</span>
              </button>
            </div>
          </div>

          <div className="table-responsive">
            <table
              className="table"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead style={TableHeadings}>
                <tr style={{ backgroundColor: "#f8f9fa" }}>
                  <th style={tableHeaderStyle}>Image</th>
                  <th style={tableHeaderStyle}>Name</th>
                  <th style={tableHeaderStyle}>Price</th>
                  <th style={tableHeaderStyle}>Seller</th>
                  <th style={tableHeaderStyle}>Data & Time</th>
                  <th style={tableHeaderStyle}>Market</th>
                  <th style={tableHeaderStyle}>Status</th>
                  <th style={tableHeaderStyle}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product) => (
                  <tr
                    key={product._id}
                    style={{ borderBottom: "1px solid #dee2e6" }}
                  >
                    <td style={tableCellStyle}>
                      <img
                        src={`${process.env.REACT_APP_LOCALHOST_URL}${product?.imageUrl}`}
                        alt={product.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    </td>
                    <td style={tableCellStyle}>{product.name}</td>
                    <td style={tableCellStyle}>${product.price.toFixed(2)}</td>
                    <td style={tableCellStyle}>{product.sellerEmail}</td>
                    <td style={tableCellStyle}>
                      {product.createdAt
                        ? new Date(product.createdAt).toLocaleString()
                        : ""}
                    </td>
                    <td style={tableCellStyle}>{product.type}</td>
                    <td style={tableCellStyle}>
                      <button
                        style={{
                          backgroundColor:
                            product.status === "approved"
                              ? "#28a745"
                              : "#ffc107",
                          color: "#fff",
                          border: "none",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {product.status}
                      </button>
                    </td>
                    <td style={tableCellStyle}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <button
                          onClick={() =>
                            handleStatusChange(product._id, product.status)
                          }
                          style={actionButtonStyle}
                        >
                          <RefreshCw size={16} />
                          Change Status
                        </button>
                        <button
                          onClick={() => handleDetailsClick(product)}
                          style={{
                            ...actionButtonStyle,
                            color: "#ef5b2b",
                            backgroundColor: "white",
                            border: "2px solid #ef5b2b",
                          }}
                        >
                          Details
                        </button>
                         <button
                          onClick={()=>startNewChat(product)}
                                      className="btn shadow-sm mt-1 ms-2"
                                      style={{ padding: "0px 10px", backgroundColor:"#f1582b", color: "white" }}
                                    >
                                     
                                      <span className="ms-1">Chat</span>
                                    </button>
                        <button
                          style={{
                            ...actionButtonStyle,
                            backgroundColor: "#dc3545",
                          }}
                        >
                          <Trash2 size={16} />
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {selectedProduct && (
            <ProductDetailsPopup
              product={selectedProduct}
              onClose={handleCloseProductPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const tableHeaderStyle = {
  padding: "15px",
  textAlign: "left",
  fontWeight: "bold",
  borderBottom: "2px solid #dee2e6",
};

const tableCellStyle = {
  padding: "15px",
  borderBottom: "1px solid #dee2e6",
};

const actionButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  padding: "5px 10px",
  borderRadius: "5px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

const TableHeadings = {
  backgroundColor: "#343a40",
  color: "#ffffff",
  position: "sticky",
  top: "0",
  zIndex: 0,
};

export default ManageProducts;
