import React from "react";
import { Carousel, Container } from "react-bootstrap";
import "./testimonial.css";
import testimonial1 from "../../../assets/testimonial/dummy-boyimage.jpg";
import testimonial2 from "../../../assets/testimonial/dummy-girlimage.png";

const Testimonial = () => {
  return (
    <div className="testimonial-topContainer">
      <Container className="testimonial-section">
        <h2 className="text-center section-title">What Our Customers Say</h2>
        <Carousel
          className="testimonial-carousel"
          interval={4000}
          controls={true} // Enable controls
        >
          <Carousel.Item>
            <div className="testimonial-item">
              <img
                src={testimonial1}
                className="testimonial-image"
                alt="testimonial"
              />
              <p className="testimonial-text">
                "When I found TradeXSell, I was astonished by the vast array of
                suppliers available from around the globe."
              </p>
              <p className="testimonial-author">Sheikh Suleman</p>
              <p className="testimonial-position">Founder of Mall</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="testimonial-item">
              <img
                src={testimonial1}
                className="testimonial-image"
                alt="testimonial"
              />
              <p className="testimonial-text">
                "TradeXSell has transformed the landscape for small businesses
                like ours.I believe we wouldn't have been able to source
                anything without it."
              </p>
              <p className="testimonial-author">Nouman Butt</p>
              <p className="testimonial-position">Business man</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="testimonial-item">
              <img
                src={testimonial2}
                className="testimonial-image"
                alt="testimonial"
              />
              <p className="testimonial-text">
                "As an entrepreneur immersed in the beauty sector, I
                have committed to crafting my products.
                TradeXSell has been a valuable ally in this endeavor."
              </p>
              <p className="testimonial-author">Zainab Ali</p>
              <p className="testimonial-position">Bank Manager</p>
            </div>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
};

export default Testimonial;
