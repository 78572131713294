import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function SellerRegister() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(""); // State for error messages
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();

  const handleRegisterValidation = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_LOCALHOST_URL}/users/addnew`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          username,
          address,
          phoneNumber,
          role: "seller",
        }),
        credentials: "include",
      });

      const data = await response.json(); // Get response data

      if (response.ok) {
        // Successful registration
        setSuccessMessage("You are registered successfully!"); // Set success message
        setError(""); // Clear any previous error

        // Remove success message after 3 seconds and redirect
        setTimeout(() => {
          setSuccessMessage(""); // Clear the success message
          navigate("/login"); // Redirect to the new page (replace '/new-page' with your desired path)
        }, 1000);
      } else {
        // Handle error if email already exists
        if (data.error) {
          setError(data.error); // Set error message
        }
      }
    } catch (error) {
      console.error("Error registering:", error);
      setError("An error occurred. Please try again."); // Handle unexpected errors
    }
  };

  const styles = {
    container: {
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: '#f7fafc',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
    },
    formBox: {
        width: '100%',
        maxWidth: '400px',
        padding: '2rem',
        backgroundColor: 'white',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
    },
    header: {
        marginBottom: '2rem',
        textAlign: 'center',
        fontSize: '1.875rem',
        fontWeight: '600',
        color: '#EF5D2E ',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
    },
    inputContainer: {
        position: 'relative',
    },
    input: {
        width: '100%',
        padding: '0.5rem 0.75rem',
        paddingLeft: '2.5rem',
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        fontSize: '1rem',
    },
    icon: {
        position: 'absolute',
        left: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#a0aec0',
    },
    forgotPassword: {
        textAlign: 'right',
        fontSize: '0.875rem',
        color: '#EF5B2B',
        cursor: 'pointer',
    },
    button: {
        width: '100%',
        padding: '0.5rem',
        backgroundColor: '#EF5B2B',
        color: 'white',
        border: 'none',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        cursor: 'pointer',
    },
    alert: {
        marginTop: '1rem',
        padding: '0.5rem',
        backgroundColor: '#fed7d7',
        color: '#9b2c2c',
        borderRadius: '0.375rem',
    },
    success: {
        marginTop: '1rem',
        padding: '0.5rem',
        backgroundColor: '#c6f6d5',
        color: '#2f855a',
        borderRadius: '0.375rem',
    },
    registerMessage: {
        marginTop: '1rem',
        textAlign: 'center',
        fontSize: '0.875rem',
        color: '#4a5568',
    },
    registerLink: {
        color: '#EF5B2B',
        cursor: 'pointer',
    },
};

  return (
    <div className="signup-container">
      <h2>Create Seller Account</h2>
      {error && <div style={styles.alert}>{error}</div>}{" "}
      {/* Show error message */}
      {successMessage && (
        <div style={styles.success}>{successMessage}</div>
      )}{" "}
      {/* Show success message */}
      <form onSubmit={handleRegisterValidation} className="signup-form">
        <input
          type="text"
          name="Username"
          value={username}
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          name="email"
          value={email}
          placeholder="Enter Email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Enter Password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="text"
          name="address"
          value={address}
          placeholder="Address"
          onChange={(e) => setAddress(e.target.value)}
          required
        />
        <input
          type="text"
          name="Phone-Number"
          value={phoneNumber}
          placeholder="Phone Number"
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />

        <button type="submit">Register</button>
      </form>
      <p className="account-info-text">
        Already have an account?{" "}
        <Link className="account-info-text-link" to="/login">
          Login
        </Link>
      </p>
    </div>
  );
}

export default SellerRegister;
