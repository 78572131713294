import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Search, Filter } from "lucide-react"; // Assuming react-bootstrap-icons is installed
import { CurrencyContext } from "../../../CurrencyContext";
import { useLocation } from "react-router-dom";


const ProductsListPage = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { currency, rates } = useContext(CurrencyContext);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCALHOST_URL}/products/all/random`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setProducts(data.length > 0 ? data : []);
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if(location.state?.filter){
      setTypeFilter(location.state.filter);
    }
  }, [location.state])
  

  const filteredProducts = products.filter((product) => {
    const matchesSearch = product.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesType =
      typeFilter === "" || product.type === typeFilter.toLowerCase();
    const matchesStatus =
      statusFilter === "" || product.status === statusFilter.toLowerCase();

    return matchesSearch && matchesType && matchesStatus;
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#f9f9f9" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h3 className="mt-3 mb-3">Products</h3>
        <p style={{ textAlign: "center" }}>No products found.</p>
      </div>
    );
  }

  return (
    <div style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>
        Products
      </h1>

      {/* Search and Filter Bar */}
      <div className="search-bar mb-3 d-flex flex-wrap align-items-center gap-3">
        <div
          className="position-relative flex-grow-1"
          style={{ maxWidth: "400px" }}
        >
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control px-3"
            style={{
              padding: "10px 40px 10px 15px",
              borderRadius: "25px",
            }}
          />
          <Search
            style={{
              position: "absolute",
              right: "15px",
              top: "50%",
              transform: "translateY(-50%)",
              color: "#6c757d",
            }}
          />
        </div>

        <div className="d-flex align-items-center">
          <Filter
            size={24}
            className="text-secondary"
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="d-flex align-items-center">
          <label htmlFor="typeFilter" className="me-2 fw-bold">
            Type:
          </label>
          <select
            id="typeFilter"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            className="form-select"
            style={{
              padding: "10px",
              borderRadius: "25px",
              maxWidth: "150px",
            }}
          >
            <option value="">All</option>
            <option value="local">Local</option>
            <option value="international">International</option>
          </select>
        </div>

      </div>

      {/* Products Grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {filteredProducts.map((product) =>{
          const convertedPrice = (product.price * (rates[currency] || 1)).toFixed(2);
           return(
          <Link
            to={`/productoverview/${product._id}`}
            key={product._id}
            style={{ textDecoration: "none" }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                transition: "transform 0.3s, box-shadow 0.3s",
                cursor: "pointer",
                textAlign: "center",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow =
                  "0 8px 20px rgba(0, 0, 0, 0.15)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow =
                  "0 4px 10px rgba(0, 0, 0, 0.1)";
              }}
            >
              <img
                src={`${process.env.REACT_APP_LOCALHOST_URL}${product.imageUrl}`}
                alt={product.name}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderBottom: "1px solid #e0e0e0",
                }}
              />
              <div style={{ padding: "15px" }}>
                <h2
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    margin: "10px 0",
                    color: "#333",
                  }}
                >
                  {product.name}
                </h2>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#28a745",
                    margin: "5px 0",
                  }}
                >{`${currency} ${convertedPrice}`}</p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    margin: "5px 0",
                    height: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "2",
                  }}
                >
                  {product.description}
                </p>
              </div>
            </div>
          </Link>
        )})}
      </div>
    </div>
  );
};

export default ProductsListPage;
