import React, { useEffect, useState } from "react";
import "./ProductWithButton.css";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function getRandomProducts(products, count) {
  const shuffled = [...products].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count); // Get the first 'count' products
}

export default function ProductWithButton() {
  const navigate = useNavigate();
  const [newArrivals, setNewArrivals] = useState([]);
  const [randomRankingProducts, setRandomRankingProducts] = useState([]);

  useEffect(() => {
    // Fetch latest products from the API
    const fetchLatestProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCALHOST_URL}/products/latest`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch latest products");
        }
        const data = await response.json();
        setNewArrivals(data); // Set the fetched data to state

        // Generate random products for Top Ranking section
        const randomProducts = getRandomProducts(data, 6); // Choose 3 random products
        setRandomRankingProducts(randomProducts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLatestProducts();
  }, []);

  const HandleLocalProducts =(data)=>{
    navigate('/allproducts', {state: {filter: data}});
  }
  return (
    <div className="container-fluid bg-custom">
      <div className="container py-5">
        <div class="container">
          <div class="row ">
            {/* ranking section  */}
            <div class="col-md-4 my-2">
              <div className="d-flex flex-column justify-content-lg-start align-items-start ">
                <div className="container bg-light rounded-3 p-1">
                  <Carousel controls={true}>
                  {randomRankingProducts.map((product, index) => (
                        <Carousel.Item key={index}>
                          <Link to="/allproducts" style={{textDecoration:"none"}}>
                          <div className="d-flex justify-content-center align-items-center flex-wrap w-100">
                            <div className="pt-2">
                              <div className="d-flex flex-column justify-content-center align-items-start p-2">
                                <p className="text-secondary">{product.name}</p>
                              </div>
                              <div className="text-center rounded">
                                <img
                                  src={`${process.env.REACT_APP_LOCALHOST_URL}${product.imageUrl}`}
                                  className="d-block mx-auto rounded"
                                  style={{
                                    maxWidth: "100%",
                                    height: "300px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          </Link>
                        </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            {/* New arriaval section  */}
            <div class="col-md-4 my-2">
              <div className="d-flex flex-column justify-content-lg-start align-items-start ">
                <div className="container bg-light rounded-3 p-1">
                  <div className="d-flex flex-column justify-content-center align-items-start p-2">
                    <p className="h5">Sample Products</p>
                  </div>

                  <div className="container">
                    <div className="row">
                      {newArrivals.slice(0, 4).map((product, index) => (
                        <div className="col-md-6 col-sm-12 p-1" key={index}>
                          <Link to="/allproducts">
                          <img
                            src={`${process.env.REACT_APP_LOCALHOST_URL}${product.imageUrl}`}
                            alt={product.name}
                            className="img-fluid rounded"
                            style={{
                              width: "100%",
                              height: "94px",
                              objectFit: "cover",
                            }}
                          />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="container bg-light rounded-3 mt-2 p-1">
                  <div className="row align-items-center p-1">
                  {newArrivals.slice(5, 6).map((product, index) => (
                    <div className="col-md-4" key={index}>
                      <Link to="/allproducts">
                      <img
                        src={`${process.env.REACT_APP_LOCALHOST_URL}${product.imageUrl}`}
                        alt={product.name}
                        className="img-fluid rounded "
                        style={{ height: "70px", objectFit: "cover",width:"100%" }}
                      />
                      </Link>
                    </div>
                    ))}
                    <div className="col-md-8">
                      <div>
                        <p className="h5 mb-0">New this week</p>
                        <p className="text-secondary">
                          Products from Verified Suppliers only
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons Section */}
            <div class="col-md-4 my-2" style={{backgroundColor: "#f8f9fb"}}>
              <div className="d-flex flex-column justify-content-lg-start align-items-start ">
                <div className="container bg-light rounded-3 p-1">
                  <div className="Three-buttonsContainer">
                    <button className="buttons-Set">
                    <Link to="/allproducts" className="text-decoration-none text-light">
                      <span class="transition"></span>
                      <span class="gradient"></span>
                      <span class="label">Products</span>
                      </Link>
                    </button>
                    <button className="buttons-Set my-3" onClick={()=>HandleLocalProducts("local")}>
                      <span class="transition"></span>
                      <span class="gradient"></span>
                      <span class="label">Local Products</span>
                    </button>
                    <button className="buttons-Set" onClick={()=>HandleLocalProducts("international")}>
                    <Link to="/allproducts" className="text-decoration-none text-light">
                      <span class="transition"></span>
                      <span class="gradient"></span>
                      <span class="label">International Products</span>
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
