import React from "react";

import { IoSearch } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import { TbWorldPin } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";

export default function SearchToFulfillment() {
    const data = [
        {
            icon : <IoSearch />,
            title: "Search for matches",
            description: "Search and discover a multitude of product and supplier options tailored to meet your business needs.",
        },
        {
            icon : <FaRegCheckCircle />,
            title: "Identify the right one",
            description: "Assess product quality and supplier capabilities quickly and effectively using verified inspections and digital sourcing solutions",
        },
        {
            icon : <RiSecurePaymentLine />,
            title: "Pay with confidence",
            description: "Complete your purchase in over 20 currencies through 20+ secure payment methods, offering flexible payment terms.",
        },
        {
            icon : <TbWorldPin />,
            title: "Fulfill with transparency",
            description: "Achieve your logistics objectives with bespoke solutions at the TradeXsell Logistics Marketplace, offering real-time tracking for more than 26,000 routes across 220 countries and regions, all facilitated by TradeXsell Logistics.",
        },
        {
            icon : <MdManageAccounts />,
            title: "Manage with ease",
            description: "Access your order status, oversee supplier relationships, track payments and shipments, and contact after-sales support, all through My TradeXsell",
        },

    ]
  return (
    <div className="container-fluid bg-light">
      <div className="container py-3 px-3">
        <div className="container w-100 pt-2 ">
          <p className="h1 fw-bold">
            Streamline ordering from search to <br />
            fulfillment, all in one place
          </p>
        </div>

        <div className="container my-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              
              {data.map((val, index) => (
                <div className="d-flex align-items-start mb-4" key={index}>
                    <div className="p-3">
                        <p className="h1" style={{color: "#ef5b2b"}}>{val.icon}</p>
                    </div>
                  <div>
                    <p className="h5">{val.title}</p>
                    <p>
                      {val.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-6">
              <img
                src="https://s.alicdn.com/@img/imgextra/i2/O1CN0168f1F61TkrjFojnmE_!!6000000002421-2-tps-1380-1060.png_q60.jpg" // 
                alt="Right Image"
                className="img-fluid"
                style={{ maxHeight: "400px", objectFit: "cover" }} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
