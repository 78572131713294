import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useHistory, useNavigate } from "react-router-dom";
import "./heroSection.css";
import arrowicon from "./arrow.jpeg";
import profiles from "./icons.jpeg";
import growth from "./growth.jpeg";
const HeroSection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState("Search here...");

  const words = [
    "Search here...",
    "Find your item...",
    "Looking for something?",
  ];
  let wordIndex = 0;

  useEffect(() => {
    const placeholderInterval = setInterval(() => {
      setPlaceholder(words[wordIndex]);
      wordIndex = (wordIndex + 1) % words.length;
    }, 2000); // Change every 2 seconds

    // Clear interval on component unmount
    return () => clearInterval(placeholderInterval);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Redirect to search results page with the query as a URL parameter
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <div>
      {/* <div className="container my-5">
        <div className="row align-items-start">
         
          <div className="col-md-5 pt-5">
            <h1 className="fw-bold">
              Welcome to <br />{" "}
              <span style={{ color: "#FF5722" }}>TradxSell Store</span>
            </h1>
            <h6 className="fw-bold mt-4 mb-4">
              The top B2B e-commerce platform for international trade
            </h6>
            <form onSubmit={handleSearch} className="input-group">
              <input
                type="text"
                className="form-control search-hero-section"
                placeholder={placeholder}
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                className="btn btn-warning"
                style={{ backgroundColor: "#ff571e", color: "white" }}
                type="submit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </button>
            </form>
          </div>

       
          <div className="col-md-5 offset-1 position-relative mt-4">
          
            <img
              src="https://img-cdn.inc.com/image/upload/f_webp,q_auto,c_fit/images/panoramic/getty_522735456_249841.jpg"
              alt="Hero"
              className="img-fluid rounded-4"
            />

          
            <div className="position-absolute top-0 start-100 translate-middle bg-white p-3 shadow rounded d-flex ">
              <div className="d-flex flex-column">
                <p className="mb-0">Sales Boost</p>
                <h5 className="text-success">+50%</h5>
              </div>
              <img src={arrowicon} style={{ width: "120px", height: "60px" }} />
            </div>

            <div className="position-absolute bottom-0 start-0 bg-white p-3 shadow rounded">
              <div className="d-flex flex-column align-items-center">
                <div className="ms-2">
                  <img
                    src={profiles}
                    style={{ width: "120px", height: "60px" }}
                  />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h6>15K</h6>
                  <p className="mb-0">Daily Users</p>
                </div>
              </div>
            </div>

            <div className="position-absolute bottom-0 end-0 bg-white p-3 shadow rounded">
              <div className="text-center">
                <div>
                  <img
                    src={growth}
                    style={{ width: "150px", height: "80px" }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <p>Users</p>
                  <p className="mb-0 fw-bold">45K</p>
                </div>
                <div className="d-flex flex-column">
                  <p>Avg Rating</p>
                  <p className="mb-0 fw-bold">4.5</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container my-5">
        <div className="row align-items-center">
          {/* Left Section */}
          <div className="col-12 col-md-6 pt-5 text-center text-md-start">
            <h1 className="fw-bold">
              Welcome to <br />
              <span style={{ color: "#FF5722" }}>TradxSell Store</span>
            </h1>
            <h6 className="fw-bold mt-4 mb-4">
              The top B2B e-commerce platform for international trade
            </h6>
            <form onSubmit={handleSearch} className="input-group">
              <input
                type="text"
                className="form-control search-hero-section"
                placeholder={placeholder}
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                className="btn btn-warning"
                style={{ backgroundColor: "#ff571e", color: "white" }}
                type="submit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </button>
            </form>
          </div>

          {/* Right Section */}
          <div className="col-12 col-md-6  mt-4 position-relative">
            {/* Background Image */}
            <img
              src="https://img.freepik.com/premium-photo/global-network-glowing-orange-yellow-lights-representing-interconnected-world_605423-207600.jpg"
              alt="Hero"
              className="img-fluid rounded-4 w-100 h-auto"
            />

            {/* Overlay Cards */}
            <div
              className="position-absolute top-0 start-0 translate-middle bg-white p-3 shadow rounded d-flex flex-column flex-sm-row align-items-center hide-for-responsive"
              style={{ maxWidth: "200px" }}
            >
              <div className="d-flex flex-column">
                <p className="mb-0 text-center text-sm-start">Sales Boost</p>
                <h5 className="text-success text-center text-sm-start">+50%</h5>
              </div>
              <img
                src={arrowicon}
                alt="arrow"
                style={{ width: "80px", height: "40px" }}
              />
            </div>

            <div
              className="position-absolute bottom-0 start-0 bg-white p-3 shadow rounded d-flex flex-column align-items-center"
              style={{ maxWidth: "150px" }}
            >
              <img
                src={profiles}
                alt="profiles"
                style={{ width: "130px", height: "60px" }}
              />
              <div className="text-center mt-2">
                <h6 className="fw-bold">15K</h6>
                <p className="mb-0">Daily Users</p>
              </div>
            </div>

            <div
              className="position-absolute bottom-0 end-0 bg-white p-3 shadow rounded d-flex flex-column align-items-center"
              style={{ maxWidth: "200px" }}
            >
              <img
                src={growth}
                alt="growth"
                style={{ width: "130px", height: "50px" }}
              />
              <div className="d-flex justify-content-between mt-2 w-100">
                <div className="text-center">
                  <p className="mb-0">Users</p>
                  <h6 className="fw-bold">45K</h6>
                </div>
                <div className="text-center">
                  <p className="mb-0">Avg Rating</p>
                  <h6 className="fw-bold">4.5</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
