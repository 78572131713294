import { React, useState } from "react";
import "./QualityAssuranceNavbar.css";
import { Link } from "react-router-dom";

export default function QualityAssuranceNavbar() {

  return (
    <div className="container container-navbar">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow">
        <div className="container-fluid">
          {/* Navbar brand */}
          <Link to="/quality/manageproducts" className="navbar-brand" style={{fontSize: "14px"}} href="#">
            Tradxsell Quality Assurance
          </Link>

          {/* Navbar links */}
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
            <li className="nav-item">
                <Link
                  className="nav-link text-light "
                  style={{ fontSize: "12px" }}
                  to="/quality/manageproducts"
                >
                  Manage Products
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-light "
                  style={{ fontSize: "12px" }}
                  to="/quality/sellercomplaints"
                >
                  Seller Complaints
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* hide this nav  */}
      <nav className="d-lg-none navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-around">
            <Link
              to="/quality/manageproducts"
              className="nav-link text-light text-center p-1 m-1 p-1 m-1"
            >
              Manage Products
            </Link>
            <Link
              to="/quality/sellercomplaints"
              className="nav-link text-light text-center p-1 m-1"
            >
              Seller Complaints
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}
